import React from "react";
import styles from "./kolaz.module.css";
export default function Kolaz({ content }) {
    return (
        <div className={styles.container}>
            <img className={styles.img} src="/assets/kolaz.jpeg" alt="ko" />
            <p className={styles.p}>
                הקולאז מייצג את דמותה של קלייר באמצעות תמונות (כוח, רואה את עצמה
                כאלוהים ובעלת הרבה כסף). קלייר היא דמות כוחנית, שטלטנית, שרואה
                את עצמה באלוהים. היא חושבת שבזכות הכסף שלה היא יכולה לקנות כל מה
                שהיא רוצה והיא מתנהגת כאילו כל העולם שייך לה. במהלך המחזה היא
                אומרת ״אני תמיד מושכת בחוטים״ ובחרתי בציטוט זה מכיוון שאני חושבת
                שזה מאפיין את הדמות הכוחנית והשתלטנית שהיא{" "}
            </p>
        </div>
    );
}
