import React from "react";
import styles from "./LittleBit.module.css";
export default function LittleBit({ title, content }) {
    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>{title}</h1>
            <hr />
            <p className={styles.content}>{content}</p>
        </div>
    );
}
