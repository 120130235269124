import React from "react";
import styles from "./Home.module.css";
export default function Home({ title }) {
    return (
        <div className={styles.container}>
            <h1 style={{ padding: "30px" }}>{title}</h1>
            <p style={{ padding: "30px" }}>שם המגישה : אור עמיר </p>
            <img
                src="https://www.danibooks.co.il/GoopSitesFiles/83758/User/catalog_543195-l.webp?638233117572900000"
                alt="back"
                width={200}
                height={300}
                style={{ marginRight: 30 }}
            />
        </div>
    );
}
