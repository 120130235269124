import React from "react";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
export default function Header({ headers }) {
    const navigate = useNavigate();
    const handleButtonClick = (href) => {
        navigate(href);
    };
    console.log(headers);

    return (
        <div className={styles.header}>
            {headers?.map((item, index) => {
                return (
                    <div key={index}>
                        <div
                            onClick={() => handleButtonClick(item.href)}
                            className={styles.item}
                        >
                            {item.title}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
